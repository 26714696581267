





































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { InputSetups } from '../../../mixins/input-setups'
import TariffsTagsHelper from '../../../mixins/TariffsTagsHelper'
import ReputationTrigger from '@/includes/logic/ReputationTrigger'
import Placeholders from '@/mixins/placeholders/placeholders'
import EditorButtonsHelpView from '@/includes/helpViews/editorButtonsHelpView.vue'
import ReputationTriggerCard from '@/components/chat/reputation/ReputationTriggerCard.vue'
import { BaseViewActionType } from '@/includes/types/Enums'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  'components': {
    ReputationTriggerCard,
    PageTitle,
    CenteredColumnLayout,
    NotAvailableOptionsOverlay,
    EmptyData,
  },
  data() {
    return {
      MediaEditorMode,
      EditorButtonsHelpView,
      ReputationTriggerCard
    }
  }
})
export default class ReputationExtendedTriggers extends Mixins<UseFields, InputSetups, TariffsTagsHelper, Placeholders>(UseFields, InputSetups, TariffsTagsHelper, Placeholders) {
  readonly PREFIX = 'reputation_triggers_'

  pageHelpMessageIsOpen = false

  reputation_special_triggers: Array<ReputationTrigger> =
    this.$store.state.chatState.chat.config.reputation_special_triggers.slice().map((item: any) => this.createTrigger(item))

  isModalOpen = false

  selectedTrigger:ReputationTrigger | null = null

  modalMode: BaseViewActionType = BaseViewActionType.New

  onCreateButtonClick():void {
    this.selectedTrigger = this.createTrigger()

    this.openModal(BaseViewActionType.New)
  }

  onTriggerEditClick(guid: string):void {
    const triggerToEdit = this.reputation_special_triggers.find(f => f.guid === guid)

    if (triggerToEdit) {
      this.selectedTrigger = cloneDeep(triggerToEdit)

      this.openModal(BaseViewActionType.Edit)
    }
  }

  openModal(modalMode: BaseViewActionType):void {
    this.modalMode = modalMode
    this.isModalOpen = true
  }

  handleOkClick():void {
    if (this.modalMode === BaseViewActionType.New) {
      this.addSpecialTrigger()
    }

    if (this.modalMode === BaseViewActionType.Edit) {
      this.saveSpecialTrigger()
    }

    this.resetFields()
  }

  addSpecialTrigger():void {
    if (this.selectedTrigger) {
      this.reputation_special_triggers.unshift(this.selectedTrigger)
    }
  }

  saveSpecialTrigger():void {
    if (this.selectedTrigger) {
      const idx = this.reputation_special_triggers.findIndex(f => f.guid === this.selectedTrigger!.guid)

      this.reputation_special_triggers.splice(idx, 1, this.selectedTrigger)
    }
  }

  deleteTrigger(index: number): void {
    this.reputation_special_triggers.splice(index, 1);
  }

  resetFields():void {
    this.selectedTrigger = null
    this.isModalOpen = false
  }

  createTrigger(data = null): ReputationTrigger {
    if(!data) {
      return new ReputationTrigger({
        message    : [
          {
            text                : "",
            buttons             : [],
            remove_after        : 0,
            send_after          : 0,
            pin                 : false,
            disable_link_preview: false,
            disable_notify      : false,
          },
        ],
        triggers   : [],
        min        : 0,
        max        : 0,
        actor_type : "All",
        target_type: "All",
      });
    } else {
      return new ReputationTrigger(data as any);
    }
  }

  getActorTypeOptions(): Promise<Array<SelectOption>> {
    return Promise.resolve([
      {
        label: this.$t(`triggers_all`),
        value: "All",
      }, {
        label: this.$t(`triggers_admin`),
        value: "Admin",
      }, {
        label: this.$t(`triggers_user`),
        value: "User",
      },
    ])
  }

  created(): void {
    this.$watch(
      'reputation_special_triggers',
      (value) => {
        this.$store.commit('pi/EXEC', {
          'fn': (): void => {
            this.$store.state.chatState.chat.config.reputation_special_triggers =
              value.map((reputationTrigger: ReputationTrigger) => reputationTrigger.getData())
          }
        })
      },
      { deep   : true, })
  }
}
