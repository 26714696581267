import makeId from "@/assets/utils/makeId";
import { cloneDeep }      from "lodash";

export default class ReputationTrigger {
	guid = makeId(12);
	message;
	triggers;
	min;
	max;
	actor_type;
	target_type;

	constructor({ message, triggers, min, max, actor_type, target_type }) {
		this.message     = message;
		this.triggers    = triggers;
		this.min         = min;
		this.max         = max;
		this.actor_type  = actor_type;
		this.target_type = target_type;
	}

	getData() {
		return cloneDeep({
			message    : this.message,
			triggers   : this.triggers,
			min        : this.min,
			max        : this.max,
			actor_type : this.actor_type,
			target_type: this.target_type,
		});
	}
}
