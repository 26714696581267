var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title',{attrs:{"tags":_vm.getTagsByFieldKey('extended_reputation')},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('reputation_extended_triggers_page_description'))}}),_c('span',{staticClass:"underline hover:cursor-pointer",on:{"click":function($event){_vm.pageHelpMessageIsOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('more'))+" ")])]),_c('a-modal',{staticClass:"reputation-extended-triggers-modal",attrs:{"title":_vm.$t('hint'),"centered":"","footer":null},model:{value:(_vm.pageHelpMessageIsOpen),callback:function ($$v) {_vm.pageHelpMessageIsOpen=$$v},expression:"pageHelpMessageIsOpen"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$t('reputation_extended_triggers_page_help_message'))}})])]},proxy:true},{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),(!_vm.getTagsByFieldKey("extended_reputation"))?[_c('div',{staticClass:"flex justify-end"},[_c('a-button',{staticClass:"mb-5",attrs:{"type":"primary","icon":"plus"},on:{"click":_vm.onCreateButtonClick}},[_vm._v(" "+_vm._s(_vm.$t((_vm.PREFIX + "create_new")))+" ")])],1),(_vm.reputation_special_triggers.length > 0)?[_vm._l((_vm.reputation_special_triggers),function(item,index){return [_c('reputation-trigger-card',{key:item.guid,attrs:{"item":item},on:{"delete":function($event){return _vm.deleteTrigger(index)},"edit":_vm.onTriggerEditClick}})]})]:_c('empty-data',{staticClass:"mt-5",attrs:{"description":(_vm.PREFIX + "list_empty")}})]:_c('a-card',{staticClass:"relative w-full mt-base"},[_c('not-available-options-overlay',{staticClass:"relative",attrs:{"video":_vm.$i18n.locale !== 'ru' ?
        'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_special_trigger_en.m4v' :
        'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_special_trigger_ru.m4v',"description":_vm.$t("option_description_extended_reputation"),"tags":_vm.getTagsByFieldKey('extended_reputation')}})],1),_c('a-modal',{attrs:{"ok-text":_vm.$t('pi_accept'),"cancel-text":_vm.$t('pi_reject')},on:{"ok":_vm.handleOkClick,"cancel":_vm.resetFields},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[(_vm.selectedTrigger !== null)?_c('div',[_c('chips-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.selectedTrigger,
            'key': 'triggers',
            'prefix': _vm.PREFIX,
            'validation': 'required'
          }
        }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': Object.assign({}, {'model': _vm.selectedTrigger,
            'key': 'message',
            'placeholders': _vm.SPEC_REP_TRIG_DEF_PLACEHOLDERS,
            'prefix': _vm.PREFIX,
            'targetToUpload': function () { return _vm.groupUploadTarget; },
            'buttonsHelpView': _vm.EditorButtonsHelpView,
            'editorMode': _vm.MediaEditorMode.TelegramShort,
            'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
            'availableButtonsTypes': _vm.defaultMessageEditorButtons,
            'base-api-url': 'api.chatkeeper.app/cabinet',
            'settingsStructure': _vm.defaultMessageEditorSettingsStructure,
            'options': {
              topics: _vm.topicsAvailable
            }},
            _vm.topicsIfForum),
        }}}),_c('number-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.selectedTrigger,
            'key': 'min',
            'prefix': _vm.PREFIX,
            'validation': 'required',
          }
        }}}),_c('number-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.selectedTrigger,
            'key': 'max',
            'prefix': _vm.PREFIX,
            'validation': 'required',
          }
        }}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.selectedTrigger,
            'key': 'actor_type',
            'prefix': _vm.PREFIX,
            'validation': 'required',
            'options': _vm.getActorTypeOptions
          }
        }}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.configInputSetup,
          'args': {
            'model': _vm.selectedTrigger,
            'key': 'target_type',
            'prefix': _vm.PREFIX,
            'validation': 'required',
            'options': _vm.getActorTypeOptions
          }
        }}})],1):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }