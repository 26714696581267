












































import { InputSetups } from '@/mixins/input-setups'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'

@Component({
  data() {
    return {
    }
  },
  components: {
  }
})
export default class ReputationTriggerCard extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @Prop() item!:any

  @Emit()
  edit():string {
    return this.item.guid
  }

  @Emit('delete')
  deleteTrigger():string {
    return this.item.guid
  }
}
